import { EventBusImpl, createEventBusHook } from "@fscrypto/state-management";
import { type GlobalUserStateEvent } from "./machines/user-state/user-state";
import { type WorkItemsGlobalEvent } from "./machines/work-items/work-items";
import { type WorkItemGlobalEvent } from "./machines/work-items/work-item";
import { type MoveWorkItemGlobalEvent } from "~/features/move-work-item/machines/move-work-item-machine";
import { type GlobalAddDashboardEvent } from "~/features/add-to-dashboard/machines/add-to-dashboard-machine";
import { type GlobalStudioEvent } from "~/features/studio";
import { type GlobalQueryRunEvent } from "~/state/machines/query-run/query-run.machine";
import type { GlobalAddOnEvent } from "./machines/marketplace-add-on/marketplace-addon.machine";
import type { GlobalRecentlyOpenedEvent } from "./machines/recently-opened/recently-opened-machine";
import type { GlobalDashboardPublishEvent } from "./machines/dashboard/dashboard-publish/dashboard-publish.machine";
import type { GlobalDashboardRoomEvent } from "./machines/dashboard/dashboard-room/dashboard-room.machine";
import type { GlobalDashboardEvent } from "./machines/dashboard/dashboard.machine";
import type { GlobalRefreshEvent } from "~/state/machines/dashboard/dashboard-refresh/dashboard-refresh.machine";
import type { GlobalDashboardParametersEvent } from "~/state/machines/dashboard/dashboard-parameters/dashboard-parameters.machine";
import type { GlobalEphemeralQueryRunEvent } from "./machines/query-run-ephemeral/query-run-ephemeral.machine";
import type { EventBusEvent as GlobalPaymentEvent } from "~/features/earn/state/use-payment-record";
import type { EventBusEvent as GlobalProfileManagerEvent } from "~/features/profile-manager/state";
import type { EventBusEvent as GlobalTeamsEvent } from "~/features/teams/actors/teams";
import type { EventBusEvent as GlobalShareSettingsEvent } from "~/features/share-settings/actors/actor";
import { EventBusEvent as GlobalWorkItemEvent } from "~/features/work-item/state/work-item/actor";
import { EventBusEvent as GlobalWorkItemExplorerEvent } from "~/features/work-item/state/work-item-explorer/actor";
import { ToasterGlobalEvent } from "~/features/toasts/toast.machine";
import { GlobalWorkItemsRoomEvent } from "./machines/work-items-room/work-items-room.machine";
import * as Events from "@fscrypto/domain/events";

import { EventBusEvent as GlobalQueryEvent } from "~/features/query/state/query/machine";
import { EventBusEvent as GlobalQueryStatementEvent } from "~/features/query/state/query-statement/machine";
import { EventBusEvent as GlobalQueryRunNewEvent } from "~/features/query/state/query-run/machine";
import { EventBusEvent as GlobalQueryParamsEvent } from "~/features/query/state/query-params/machine";
import { EventBusEvent as GlobalQueryResultsPanelEvent } from "~/features/query/state/query-results-panel/machine";
import { EventBusEvent as GlobalQueryPanelsEvent } from "~/features/query/state/query-panels/machine";
import { EventBusEvent as GlobalQueryVisualizationEvent } from "~/features/query/state/query-visualization-panel/machine";
import { EventBusEvent as GlobalQueryRoomEvent } from "~/features/query/state/query-room/machine";
import { EventBusEvent as GlobalSchemaEvent } from "~/state/machines/autocomplete-schema/autocomplete-schema";

import { EventBusEvent as GlobalVisualizationRootEvent } from "~/features/visualization/state/visualization/machine";
import { EventBusEvent as GlobalVisualizationRoomEvent } from "~/features/visualization/state/visualization-room/machine";
import {
  GlobalWalletUpsertSuccessEvent,
  GlobalWalletUpsertFailureEvent,
  GlobalWalletDeleteSuccessEvent,
  GlobalWalletSetDefaultSuccessEvent,
  GlobalWalletUpsertInProgressEvent,
} from "~/features/wallet-management/state/use-wallet";
type BaseGlobalEvent =
  | ToasterGlobalEvent
  | WorkItemsGlobalEvent
  | WorkItemGlobalEvent
  | MoveWorkItemGlobalEvent
  | GlobalQueryRunEvent
  | GlobalAddDashboardEvent
  | GlobalStudioEvent
  | GlobalAddOnEvent
  | { type: "GLOBAL.SET_ACTIVE_QUERY"; payload: string | null }
  | GlobalRecentlyOpenedEvent
  | GlobalUserStateEvent
  | { type: "GLOBAL.SIDEBAR.TOGGLE_OPEN"; payload: boolean }
  | { type: "LIVEBLOCKS_INIT" }
  | GlobalDashboardRoomEvent
  | GlobalDashboardPublishEvent
  | GlobalDashboardEvent
  | GlobalRefreshEvent
  | GlobalDashboardParametersEvent
  | GlobalEphemeralQueryRunEvent
  | GlobalPaymentEvent
  | GlobalWorkItemsRoomEvent
  | GlobalProfileManagerEvent
  | GlobalTeamsEvent
  | GlobalShareSettingsEvent
  | GlobalWorkItemEvent
  | GlobalWorkItemExplorerEvent
  | GlobalSchemaEvent
  // QUERY EVENTS
  | GlobalQueryEvent
  | GlobalQueryStatementEvent
  | GlobalQueryRunNewEvent
  | GlobalQueryParamsEvent
  | GlobalQueryResultsPanelEvent
  | GlobalQueryPanelsEvent
  | GlobalQueryVisualizationEvent
  | GlobalQueryRoomEvent
  // VISUALIZATION EVENTS
  | GlobalVisualizationRootEvent
  | GlobalVisualizationRoomEvent
  // WALLET EVENTS
  | GlobalWalletUpsertSuccessEvent
  | GlobalWalletUpsertFailureEvent
  | GlobalWalletDeleteSuccessEvent
  | GlobalWalletSetDefaultSuccessEvent
  | GlobalWalletUpsertInProgressEvent;

export type GlobalEvent = WithOptionalMeta<BaseGlobalEvent>;

/**
 * To listen for global events in an actor, simply invoke
 * this subject at the root level and add `GlobalEvents`
 * to the inferred event type.
 *
 * invoke: {
 *   id: "event-bus",
 *   src: () => eventBus.events$,
 * }
 */

type Meta = { fromRealtime?: boolean };
type WithOptionalMeta<T> = T & Meta;

export type GlobalEventSelector<T extends GlobalEvent["type"]> = Extract<GlobalEvent, { type: T }>;

export const eventBus = new EventBusImpl<GlobalEvent>();
type V2GlobalEvent = Events.VisualizationEvent | Events.QueryEvent | Events.DashboardEvent | Events.WorkItemEvent;
const eventBusV2 = new EventBusImpl<V2GlobalEvent>();

export const v2 = {
  eventBus: eventBusV2,
  useEvent: createEventBusHook(eventBusV2),
};

export const useEvent = createEventBusHook(eventBus);
