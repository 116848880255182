import type { Observable } from "rxjs";
import { Subject, share, switchMap, timer } from "rxjs";
import { GenericEvent } from "./event";

export interface EventBus<E extends GenericEvent> {
  send(event: E): void;
  events$: Observable<E>;
  debounceSend(event: E, debounceTime: number): void;
}

export class EventBusImpl<E extends GenericEvent> implements EventBus<E> {
  #events$$ = new Subject<E>();

  // Map to hold debouncing subjects for each event type
  #debounceSubjects = new Map<string, Subject<{ event: E; debounceTime: number }>>();

  public events$ = this.#events$$.asObservable().pipe(share());

  constructor() {}
  public send(event: E): void {
    this.#events$$.next(event);
  }
  // debounce eventBus events
  public debounceSend(event: E, debounceTime: number): void {
    // Check if there's already a subject for this event type
    let debounceSubject = this.#debounceSubjects.get(event.type);

    if (!debounceSubject) {
      // If not, create a new subject for this event type
      debounceSubject = new Subject<{ event: E; debounceTime: number }>();
      this.#debounceSubjects.set(event.type, debounceSubject);

      // Subscribe to this new subject
      debounceSubject
        .pipe(
          // Restart the timer with each new event of this type
          switchMap(({ event, debounceTime }) => timer(debounceTime).pipe(switchMap(() => [event]))),
        )
        .subscribe((event) => {
          // Send the debounced event to the main event stream
          this.#events$$.next(event);
        });
    }

    // Emit the event along with its debounce time to the specific debouncing subject
    debounceSubject.next({ event, debounceTime });
  }
}
