import { useSubscription } from "observable-hooks";
import { filter } from "rxjs";
import { EventBus, GenericEvent } from "../core";

type EventSelector<E extends GenericEvent, T extends E["type"]> = Extract<E, { type: T }>;

/**
 * Function that creates a hook for a given event bus
 * @param bus
 * @returns A react hook that fires when an event is emitted on the bus
 */
export const createEventBusHook =
  <E extends GenericEvent>(bus: EventBus<E>) =>
  <T extends E["type"], Ev extends EventSelector<E, T>>(e: T, cb: (e: Ev) => void) => {
    useSubscription(bus.events$.pipe(filter((ev): ev is Ev => ev.type === e)), (e) => cb(e));
  };
